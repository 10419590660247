import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';

import routesMap from '../../../Routes';
import PaymentPage from '../../../components/PaymentPage';
import callApi from '../../../services/api';
import { registerCard } from '../../../services/mangopay';
import { trackEvent } from '../../../services/analytics';
import Layout from '../../../layouts/index';


class PaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitCard = this.submitCard.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  redirect3DS(redirectUrl, total) {
    if (redirectUrl) {
      trackEvent('payment', '3Ds_redirect', 'basket', total);
      this.setState({ redirecting: true });
      window.location = redirectUrl;
    } else {
      this.redirectToSuccess(total);
    }
  }

  redirectToSuccess() { // eslint-disable-line
    trackEvent('payment', 'redirectToSuccess', 'basket');
    navigate(routesMap.SuccessPayment.url);
  }

  submitCard(cardData, orderId, customerId) {
    this.setState({ error: false, isLoading: true, errorType: undefined });
    return registerCard(cardData, customerId)
      .then(res => console.log(res) || res)
      .then(card => callApi('public/submitCard', 'post', { card, orderId }))
      .then(() => this.redirectToSuccess())
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        trackEvent('erreur', response.error);
        if (typeof Sentry !== 'undefined') {
          Sentry.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  render() {
    const order = this.props.location && this.props.location.state &&
      this.props.location.state.order;
    if (typeof location === 'undefined') return null;
    return (
      <Layout>
        <Router
          location={location}
          key={location.key}
        >
          <PaymentPage
            path="/enregistrer-votre-moyen-de-paiement/:orderId/:customerId"
            order={order}
            error={this.state.error}
            errorType={this.state.errorType}
            isLoading={this.state.isLoading}
            submit={this.submitCard}
          />
        </Router>
      </Layout>
    );
  }
}

PaymentContainer.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      order: PropTypes.shape({}),
    }),
  }),
};

PaymentContainer.defaultProps = {
  location: {},
};

export default PaymentContainer;
